<template>
  <el-menu
    :default-active="$route.path"
    router
    background-color="#1e282c"
    text-color="#fff"
    :unique-opened="true"
  >
    <el-submenu v-for="(value, key) in allMenu" :key="key" :index="key">
      <template slot="title">
        <i :class="value.icon" style="font-size: 12px"></i>
        <span>{{ key }}</span>
      </template>
      <el-menu-item
        v-for="_value in value.children"
        :key="_value.name"
        :index="_value.name"
      >
        <span>{{ _value.key }}</span>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      allMenu: [],
    }
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getMenu();
  },
  methods: {
    getMenu() {
      let self = this;
      let allMenu = {
        账号管理: {
          icon: "el-icon-user-solid",
          children: [{ key: "账号列表", name: "/admin/list" }]
        },
        责任护士: {
          icon: "el-icon-s-custom",
          children: [{ key: "人员列表", name: "/nurse/list" }]
        },
        用户管理: {
          icon: "el-icon-s-custom",
          children: [{ key: "用户列表", name: "/user/userList" }]
        },
        乐享脉动: {
          icon: "el-icon-headset",
          children: [{ key: "视频列表", name: "/happyPulse/videoList" }]
        },
        心理树洞: {
          icon: "el-icon-edit",
          children: [{ key: "问答列表", name: "/treeHole/q&AList" }]
        },
        心理课堂: {
          icon: "el-icon-reading",
          children: [
            { key: "文章列表", name: '/psychologicalClassroom/reportList' },
            { key: "标签列表", name: '/psychologicalClassroom/tagList' }
          ]
        },
        帮助中心: {
          icon: "el-icon-bangzhu",
          children: [
            { key: "文章列表", name: '/helpCenter/reportList' },
            { key: "视频列表", name: '/helpCenter/videoList' }
          ]
        },
        药品管理: {
          icon: "el-icon-coin",
          children: [{ key: "药品库", name: '/drugAdministration/drugClassList' }]
        },
        数据统计: {
          icon: "el-icon-coin",
          children: [{ key: "数据大屏", name: '/dashboard/index' }]
        }
      };
      self.allMenu = allMenu;
    }
  }
};
</script>
<style scoped>
.el-submenu {
  text-align: left;
}
</style>
